// Generated by Framer (c75d380)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./vjnvkJRPJ-0.js";

const cycleOrder = ["CFW5C6ZyA"];

const serializationHash = "framer-eYW3I"

const variantClassNames = {CFW5C6ZyA: "framer-v-vi5mgm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {eWWczOETO: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CFW5C6ZyA", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-vi5mgm", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"CFW5C6ZyA"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1ea8mfg"} data-framer-name={"SVG"} layout={"position"} layoutDependency={layoutDependency} layoutId={"CX4PIKOxE"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\"><path d=\"M 19.25 12 C 19.25 16.004 16.004 19.25 12 19.25 C 7.996 19.25 4.75 16.004 4.75 12 C 4.75 7.996 7.996 4.75 12 4.75 C 16.004 4.75 19.25 7.996 19.25 12 Z M 10.409 10.409 L 15.25 8.75 L 13.591 13.591 L 8.75 15.25 Z\" fill=\"transparent\" stroke-width=\"1.5\" stroke=\"var(--token-72be4b58-ad30-40d4-9403-e97ffbb2d1b3, rgb(246, 246, 246)) /* {&quot;name&quot;:&quot;Text&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\"\"></path></svg>"} svgContentId={3814164485} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-eYW3I[data-border=\"true\"]::after, .framer-eYW3I [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eYW3I.framer-31x16e, .framer-eYW3I .framer-31x16e { display: block; }", ".framer-eYW3I.framer-vi5mgm { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-eYW3I .framer-1ea8mfg { flex: none; height: 24px; left: calc(50.00000000000002% - 24px / 2); position: absolute; top: calc(50.00000000000002% - 24px / 2); width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramervjnvkJRPJ: React.ComponentType<Props> = withCSS(Component, css, "framer-eYW3I") as typeof Component;
export default FramervjnvkJRPJ;

FramervjnvkJRPJ.displayName = "SVG";

FramervjnvkJRPJ.defaultProps = {height: 24, width: 24};

addFonts(FramervjnvkJRPJ, [])